import type { CartItem } from '~types/clientStore'

import { PositionType } from '~api/consts'

type cartComposable = {
  callForGiftsPopup: () => Promise<void>
  deleteItem: (checkedItem: CartItem, confirmed: boolean) => Promise<void>
  deleteItemsArray: (products: CartItem[], confirmed?: boolean) => Promise<void>
  items: ComputedRef<CartItem[]>
}
export default function useCart(): cartComposable {
  const accountStore = useAccountStore()

  const appConfig = useAppConfig()
  const clientStore = useClientStore()

  const items = computed<CartItem[]>(() => {
    let cartContent: CartItem[] =
      (clientStore.ClientState.data?.Cart?.Content ?? []).filter(
        (item: CartItem) => item.PositionType !== PositionType.Additional
      ) ?? []

    // if there is no need to show multiple gifts - filter them
    if (!appConfig.VueSettingsPreRun.CartShowMultipleGifts) {
      cartContent = cartContent.filter(
        (item: CartItem) => item.PositionType !== PositionType.MultipleGift
      )
    }

    if (
      accountStore.isLoggedIn && // if there is no need to show birthday gifts - filter them
      !appConfig.VueSettingsPreRun.CartShowBirthdayGifts
    ) {
      cartContent = cartContent.filter(
        (item: CartItem) => item.PositionType !== PositionType.BirthPresent
      )
    }

    return cartContent
  })

  async function callForGiftsPopup(): Promise<void> {
    const menuStore = useMenuStore()
    const popupStore = usePopupStore()

    if (
      menuStore.GiftsMultiple.state !== 'success' &&
      menuStore.GiftsMultiple.state !== 'loading'
    ) {
      await menuStore.loadGiftsMultiple()
    }

    popupStore.openPopup({
      popupClosable: true,
      popupName: 'menuGiftsPopup',
      popupProperties: new Map<string, unknown>([
        ['allowMultiple', menuStore.GiftsMultiple.data?.AllowMultipleGifts],
        ['gifts', menuStore.GiftsMultiple.data?.Gifts],
        ['type', 'promocode']
      ])
    })
  }

  async function deleteItem(
    checkedItem: CartItem,
    confirmed: boolean
  ): Promise<void> {
    await clientStore.deleteCartItem({
      confirmed: confirmed,
      groupID: checkedItem.GroupID,
      itemCount: checkedItem.Count,
      itemId: checkedItem.ID,
      name: checkedItem.Name
    })
  }

  async function deleteItemsArray(
    products: CartItem[],
    confirmed = false
  ): Promise<void> {
    await Promise.all(
      products.map(async (item) => {
        await deleteItem(item, confirmed)
      })
    )
  }

  return {
    callForGiftsPopup,
    deleteItem,
    deleteItemsArray,
    items
  }
}
